aside {
    .profile-img-wrapper {
        text-align: center;

        img {
            width: 60px;
            height: 60px;
            border-radius: 50%;
        }
    }

    .profile-name {
        font-size: 18px;
        font-weight: $font-weight-semibold;
        text-align: center;
        line-height: 30px;
    }

    .profile-designation {
        padding: 5px 25px;
        font-size: 12px;
        line-height: 20px;
        font-weight: $font-weight-medium;
        margin-bottom: 10px;
    }

    .social-links {
        margin-bottom: 1rem;
        text-align: center;

        .social-link {
            display: inline-block;
            font-size: 12px;
            line-height: 1;
            color: $primary;
            text-align: center;
            background-color: $white;
            padding: 8px;
            width: 28px;
            height: 28px;
            border-radius: 50%;
            margin-right: 4px;

            &:last-child {
                margin-right: 0;
            }
        }
    }

    .widget {
        text-align: center;
        margin-bottom: 28px;
        .widget-title {
            font-size: 14px;
            font-weight: $font-weight-medium;
            text-transform: uppercase;
            margin-bottom: 10px;
        }
        .widget-content {
            font-size: 13px;
            p{
                margin-bottom: 5px;

                &:last-of-type {
                    margin-bottom: 10px;
                }
            }
        }

        &.card {
            border: 0;
            border-radius: 10px;
            box-shadow: 0 2px 20px 0 rgba(0, 0, 0, 0.08);

            .widget-title {
                color: $primary;
            }
        }
    }
}