.btn-share {
    padding: 5px 25px;
    border: 0;
    border-radius: 15px;
    font-size: 0.75rem;
    font-weight: $font-weight-medium;
    line-height: 1.67;
    height: 30px;

    @include hover-focus-active{
        background-color: $white;
        border: 0;
    }

    &:focus {
        box-shadow: none;
    }

    &:hover {
        box-shadow: 0 3px 5px 0 rgba($black, 0.2);
    }
}

.btn-img {
    height: 10px;
    margin-right: 6px;
}

.btn-menu-toggle {
    padding: 12.5px 12.5px;
    width: 35px;
    height: 35px;
    border: 0;
    line-height: 10px;
    transition: all 0.2s ease-in-out;

    img {
        height: 10px;
    }

    @include hover-focus-active{
        background-color: $white;
        border: 0;
    }

    &:focus {
        box-shadow: none;
    }

    &:hover {
        box-shadow: 0 3px 5px 0 rgba($black, 0.2);
    }
}

.btn-download-cv {
    padding:6px 30px;
}

.btn-hire-me {
    font-size: 8px;
    font-weight: $font-weight-medium;
    line-height: 1;
    padding: 8px 12px;
}