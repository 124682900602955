body {
    padding: 20px 60px;

    @media(max-width: 991px) {
        padding-top: 87px;
    }

    @media (max-width: 767px) {
        padding-left: 20px;
        padding-right: 20px;
    }
}

header {
    display: flex;
    align-items: center;

    @media (max-width: 991px) {
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        padding-top: 20px;
        padding-right: 60px;
        padding-left: 300px;
        box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.08);
    }

    @media (max-width: 767px) {
        padding-left: 20px;
        padding-top: 20px;
        padding-right: 20px;
    }
}

.content-wrapper {
    display: flex;

    @media (max-width: 767px) {
        flex-direction: column;
    }
}

aside {
    padding-right: 57px;
    min-width: 240px;

    @media (max-width: 767px) {
        width: 100%;
        padding-right: 0;
    }
}

main {
    flex-grow: 1;
}