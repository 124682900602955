header {
    display: flex;
    padding-left: 240px;
    padding-top: 16px;
    padding-bottom: 16px;
    background-color: $body-bg;
    z-index: 1000;
}
.collapsible-nav {
    display: flex;
    align-items: center;
    width: 0px;
    overflow: hidden;
    margin-right: 10px;
    transition: width 0.6s ease-in-out;

    &.show {
        width: 365px;
    }

    @media(max-width: 991px) {
        position: fixed;
        right: 0;
        bottom: 0;
        overflow: auto;
        flex-direction: column;
        top: 71px;
        align-items: flex-start;
        background-color: $white;
        margin-right: 0;
        padding-left: 24px;
        padding-right: 24px;
        width: 200px;
        transform: translateX(100%);
        transition: transform 0.4s ease-in-out, width 0s;
        box-shadow: 0 2px 20px 0 rgba(0, 0, 0, 0.08);

        &.show {
            transform: translateX(0%);
            width: 200px;
        }
    }

    .nav-link {
        font-size: 12px;
        font-weight: $font-weight-semibold;
        color: $black;
        text-transform: uppercase;
        padding: 2px 0;
        height: 24px;
        margin: 0 15px;

        @media(max-width: 991px) {
            margin: 15px 0;
            height: auto;
        }

        &.active {
            color: $primary;
            border-bottom: 2px solid $primary;
            padding-bottom: 0;
        }
    }
}