$duration: 300ms;
$timing-fn: ease;

main {
    padding: 36px 60px 24px;
    background-color: $white;
    border-top-right-radius: 10px;

    @media (max-width: 767px) {
        padding-left: 20px;
        padding-right: 20px;
    }

    .section-title {
        font-size: 25px;
        line-height: 1;
        font-weight: $font-weight-bold;
        text-transform: uppercase;
        margin-bottom: 2.125rem;
    }

    .section-subtitle {
        font-size: 12px;
        font-weight: $font-weight-medium;
        line-height: 1;
        color: $primary;
    }

    p {
        font-size: 13px;
        line-height: 1.538;
        font-weight: $font-weight-light;
    }

    .intro-section {
        margin-bottom: 29px;

        .section-title {
            margin-bottom: 1rem;
        }
    }

    .resume-section {
        margin-bottom: 47px;
    }

    .time-line {
        list-style: none;
        padding-left: 0;
        margin-bottom: 24px;

        &-item {
            line-height: 0;
            padding-left: 40px;
            padding-bottom: 24px;
            position: relative;

            &::before {
                content: "";
                display: block;
                position: absolute;
                height: 100%;
                left: 5px;
                transform: translateX(-50%);
                width: 1px;
                background-color: #f3f3f3;
            }

            &::after {
                content: "";
                display: block;
                position: absolute;
                top: 0;
                left: 0px;
                width: 10px;
                height: 10px;
                border-radius: 50%;
                background-color: #979797;
            }

            &:nth-child(2n) {
                &::after {
                    background-color: $primary;
                }
            }

            &:not(:first-child) {
                &::after {
                    top: 5px;
                }
            }

            &:last-child {
                padding-bottom: 0;
            }

            .badge {
                padding: 6px 10px;
                font-size: 8px;
                font-weight: $font-weight-medium;
                line-height: 1;
                border-radius: 2px;
                margin-bottom: 8px;
            }

            &-title {
                font-size: 12px;
                font-weight: $font-weight-bold;
                line-height: 1;
                margin-bottom: 2px;
            }

            &-subtitle {
                font-size: 10px;
                font-weight: $font-weight-medium;
                line-height: 1.6;
                color: #a7a7a7;
                margin-bottom: 3px;
            }

            &-content {
                font-size: 10px;
                font-weight: $font-weight-light;
                line-height: 1.6;
                margin-bottom: 0;
            }
        }
    }

    .services-section {
        margin-bottom: 34px;
    }

    .service-card {
        padding-top: 11px;
        padding-bottom: 11px;

        &:first-child,
        &:nth-child(3) {
            border-right: 1px solid #f3f3f3;

            @media (max-width: 991px) {
                border-right: 0;
            }
        }

        @media (max-width: 991px) {
            border-bottom: 1px solid #f3f3f3;

            &:last-child {
                border-bottom: 0;
            }
        }

        .service-icon {
            width: 29.9px;
            height: 29.9px;
            margin-right: 17.1px;
            display: flex;
            height: 100%;
            align-items: center;
            justify-content: center;
        }

        .service-title {
            font-size: 12px;
            font-weight: bold;
            line-height: 1;
            margin-bottom: 8px;
        }

        .service-description {
            font-size: 10px;
            font-weight: $font-weight-light;
            max-width: 180px;
            margin-bottom: 0;
        }
    }

    .testimonial-section {
        background-color: #080808;
        color: $white;
        margin-bottom: 26px;
    }

    .testimonial-carousel {
        padding: 30px;

        .carousel-item {
            text-align: center;
        }

        .testimonial-content {
            font-size: 10px;
            font-weight: $font-weight-light;
            max-width: 280px;
            margin-left: auto;
            margin-right: auto;
            margin-bottom: 5px;
        }

        .testimonial-img {
            width: 20px;
            height: 20px;
            border-radius: 50%;
            margin-bottom: 3px;
        }

        .testimonial-name {
            font-size: 10px;
            font-weight: $font-weight-medium;
            line-height: 1.2;
            color: $primary;
        }

        .carousel-indicators {
            position: static;
            margin-bottom: 0;

            li {
                width: 3px;
                border-radius: 50%;
                border: 0;
            }
        }
    }

    footer {
        font-size: 10px;
        font-weight: $font-weight-medium;
        line-height: 1.2;
        padding-top: 5px;
        padding-bottom: 5px;
        text-align: center;
    }

    .clients-section {
        margin-bottom: 45px;
    }

    .client-logos-wrapper {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;

        .client-logo {
            width: calc(25% - 10px);
            border: 1px solid #f3f3f3;
            padding: 10px 15px;
            height: 65px;
            display: flex;
            align-items: center;
            justify-content: center;
            margin-bottom: 20px;

            @media (max-width: 991px) {
                width: calc( 50% - 10px);
            }

            @media (max-width: 767px) {
                width: calc( 25% - 10px);
            }

            @media (max-width: 575px) {
                width: calc( 50% - 10px);
            }

            img {
                max-width: 100%;
                max-height: 21px;
            }
        }
    }

    .achievements-section {
        margin-bottom: 29px;
    }

    .achievement-cards-wrapper {
        display: flex;
        justify-content: space-around;
        padding: 35px 15px;
        background-color: #f7f7f9;

        @media (max-width: 991px) {
            flex-direction: column;
        }

        @media (max-width: 767px) {
            flex-direction: row;
        }

        @media (max-width: 575px) {
            flex-direction: column;
        }
    }

    .achievement-card {

        @media (max-width: 991px) {
            margin-bottom: 35px;

            &:last-child {
                margin-bottom: 0;
            }
        }

        @media (max-width: 767px) {
            margin-bottom: 0;
        }

        @media (max-width: 575px) {
            margin-bottom: 35px;

            &:last-child {
                margin-bottom: 0;
            }
        }

        &-icon {
            width: 30px;
            height: 30px;
            margin-right: 10px;
        }

        &-title {
            font-size: 20px;
            font-weight: $font-weight-bold;
            margin-bottom: 0;
            line-height: 1;
        }

        &-description {
            font-size: 10px;
            font-weight: $font-weight-bold;
            line-height: 1.2;
            margin-bottom: 0;
            color: #070707;
        }
    }

    .portfolio-section {
        margin-bottom: 33px;
    }

    .portfolio-wrapper {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;

        .portfolio-item {
            width: calc(25% - 10.5px);
            height: 300px;
            margin-bottom: 15px;
            background-color: #f2f2f2;
            display: flex;
            justify-content: center;
            align-items: center;

            @media (max-width: 1500px) {
                width: calc(50% - 7px);
            }

            @media (max-width: 991px) {
                width: 100%;
            }

            @media (max-width: 767px) {
                width: calc(50% - 7px);
            }

            @media (max-width: 575px) {
                width: 100%;
            }

            &-img {
                max-height: 198px;
                max-width: 100%;
            }

            &-details {
                padding: 22px 26px;
                background-color: rgba($black, 0.42);
                color: $white;
            }

            &-title {
                font-size: 10px;
                font-weight: $font-weight-bold;
                line-height: 1;
                margin-bottom: 5px;
            }

            &-description {
                font-size: 10px;
                line-height: 1;
                margin-bottom: 0;
            }
        }
    }

    .blog-section {
        margin-bottom: 51px;
    }

    .blog-posts-wrapper {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;

        .blog-post {
            width: calc(33.33% - 15px);
            margin-bottom: 24px;

            @media (max-width: 1200px) {
                width: calc(50% - 8.5px);
            }

            @media (max-width: 575px) {
                width: 100%;
            }

            &-link {
                color: inherit;

                &:hover {
                    text-decoration: none;
                }
            }

            &-thumbnail {
                width: 100%;
                margin-bottom: 13px;
            }

            &-title {
                font-size: 15px;
                font-weight: $font-weight-medium;
                line-height: 1;
                margin-bottom: 6px;
            }

            &-meta {
                color: $primary;
                text-transform: uppercase;
                font-size: 10px;
                font-weight: $font-weight-medium;
                line-height: 1.2;
                margin-bottom: 0;

                .post-published-date {
                    margin-right: 6px;
                }
            }
        }
    }

    .contact-section {
        margin-bottom: 31px;

        p {
            font-size: 12px;
            font-weight: $font-weight-light;
        }
    }

    .contact-cards-wrapper {
        display: flex;
        flex-wrap: wrap;
        overflow: hidden;
        justify-content: space-between;
    }

    .contact-card {
        border: 1px solid #f3f3f3;
        padding: 25px 30px 20px;
        margin-bottom: 28px;
        width: calc(50% - 12.5px);

        @media (max-width: 991px) {
            width: 100%;
        }

        @media (max-width: 767px) {
            width: calc(50% - 12.5px);
        }

        @media (max-width: 575px) {
            width: 100%;
        }

        &-title {
            font-size: 14px;
            font-weight: $font-weight-bold;
            line-height: 1;
            margin-bottom: 0;
        }

        &-content {
            font-size: 12px;
            line-height: 1.83;
            margin-bottom: 0;
        }
    }

    .contact-form {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        .form-group {
            margin-bottom: 21px;
            width: 100%;
            padding: 0;

            &-name, &-email {
                width: calc(50% - 12.5px);

                @media (max-width: 991px) {
                    width: 100%;
                }
        
                @media (max-width: 767px) {
                    width: calc(50% - 12.5px);
                }
        
                @media (max-width: 575px) {
                    width: 100%;
                }
            }
        }
        .form-control {
            border: 0;
            border-radius: 0;
            background-color: #f3f3f3;
            padding: 12px 14px;
            color: $black;
            font-size: 8px;
            line-height: 1.5;
            min-height: 36px;
        }

        .form-submit-btn {
            border-radius: 0;
            font-size: 12px;
            font-weight: $font-weight-bold;
            padding: 10px 29px;
        }
    }

    .location-section {
        margin-bottom: 66px;

        .section-title {
            font-size: 14px;
            font-weight: $font-weight-bold;
            line-height: 1.9;
            margin-bottom: 14px;
        }

        .map-wrapper {
            height: 150px;
        }
    }
}

.hover-box {
    position: relative;
    perspective: 800px;
    overflow: hidden;

    .overlay {
        position: absolute;
        left: 0;
        top: 0;
        height: 100%;
        width: 100%;
        transform: rotate3d(1, 0, 0, 90deg);
        background-color: rgba($black, 0.42);
        color: $white;
        pointer-events: none;
    }

    &.in-up .overlay {
        transform-origin: 50% 0%;
        animation: in-up $duration $timing-fn 0ms 1 forwards;
    }

    &.in-right .overlay {
        transform-origin: 100% 0%;
        animation: in-right $duration $timing-fn 0ms 1 forwards;
    }

    &.in-down .overlay {
        transform-origin: 50% 100%;
        animation: in-down $duration $timing-fn 0ms 1 forwards;
    }

    &.in-left .overlay {
        transform-origin: 0% 0%;
        animation: in-left $duration $timing-fn 0ms 1 forwards;
    }

    &.out-up .overlay {
        transform-origin: 50% 0%;
        animation: out-up $duration $timing-fn 0ms 1 forwards;
    }

    &.out-right .overlay {
        transform-origin: 100% 50%;
        animation: out-right $duration $timing-fn 0ms 1 forwards;
    }

    &.out-down .overlay {
        transform-origin: 50% 100%;
        animation: out-down $duration $timing-fn 0ms 1 forwards;
    }

    &.out-left .overlay {
        transform-origin: 0% 0%;
        animation: out-left $duration $timing-fn 0ms 1 forwards;
    }
}

@keyframes in-up {
    from {
        transform: rotate3d(-1, 0, 0, 90deg)
    }

    to {
        transform: rotate3d(0, 0, 0, 0deg)
    }
}

@keyframes in-right {
    from {
        transform: rotate3d(0, -1, 0, 90deg)
    }

    to {
        transform: rotate3d(0, 0, 0, 0deg)
    }
}

@keyframes in-down {
    from {
        transform: rotate3d(1, 0, 0, 90deg)
    }

    to {
        transform: rotate3d(0, 0, 0, 0deg)
    }
}

@keyframes in-left {
    from {
        transform: rotate3d(0, 1, 0, 90deg)
    }

    to {
        transform: rotate3d(0, 0, 0, 0deg)
    }
}

@keyframes out-up {
    from {
        transform: rotate3d(0, 0, 0, 0deg)
    }

    to {
        transform: rotate3d(-1, 0, 0, 104deg)
    }
}

@keyframes out-right {
    from {
        transform: rotate3d(0, 0, 0, 0deg)
    }

    to {
        transform: rotate3d(0, -1, 0, 104deg)translateX(-1px);
    }
}

@keyframes out-down {
    from {
        transform: rotate3d(0, 0, 0, 0deg)
    }

    to {
        transform: rotate3d(1, 0, 0, 104deg)
    }
}

@keyframes out-left {
    from {
        transform: rotate3d(0, 0, 0, 0deg)
    }

    to {
        transform: rotate3d(0, 1, 0, 104deg)
    }
}